<template lang="pug">
  FormModal.modal-edit-collection(title="Edit Collection", :endpoint="`collections/${this.collection_id}`", method="PUT", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(label="Privacy", name="type", v-if="user.hasPermission('collections.shared_manage') && user.hasPermission('collections.manage')")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared Collection
            div.option-label Shared among all members of your team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal Collection
            div.option-label Private and visible only to you.

    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", prepend-icon="collection", textarea, :rows="1", auto-focus)

    InputGroup(label="Folder", name="folder_id", v-if="data.type")
      Select(v-model="data.folder_id", :endpoint="`options/${data.type}/folders`", option-label="label", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas")

    InputGroup(label="Language", name="language_id")
      Select(v-model="data.language_id", endpoint="options/languages", prepend-icon="language", prepend-icon-prefix="fas")

    InputGroup(label="Internal code — optional", name="code", description="Press 'enter' after each code")
      TagsInput(v-model="data.code", placeholder="Enter code...", prepend-icon="tag")

    InputGroup(label="Markets — optional", name="partner_market_ids", description="Press 'enter' after each market. Apply a specific market to this content experience. Note: Leaving this blank makes it accessible to all users everywhere without restriction.")
      MultiSelect(v-model="data.partner_market_ids", placeholder="Enter market...", endpoint="search/partner-markets", searchable)

    PublishAtInput(v-model="data.inspires_publish_at", label="Make public on inspires - optional", description="Choose a date to make this content experience “public,” meaning available to all users on inspires.to. NB: Do not set a date if this content experience is “private,” meaning available to only a select group of users.")
    ExpiresAtInput(v-model="data.expires_at", description="Choose a date to remove this content experience from the view of all users on inspires.to. NB: This expiration will also apply to “private” content experiences.")
</template>

<script>
import { merge, pick } from "lodash-es"
import { mapGetters } from "vuex"
import ExpiresAtInput from "@/components/Form/ExpiresAtInput.vue"
import PublishAtInput from "@/components/Form/PublishAtInput.vue"

export default {
  components: {
    ExpiresAtInput,
    PublishAtInput
  },
  props: {
    collection_id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    data: {
      type: null,
      name: "",
      folder_id: null,
      language_id: null,
      code: [],
      partner_market_ids: [],
      inspires_publish_at: null,
      expires_at: ""
    }
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  async created() {
    let response = await this.$api.get(`collections/${this.collection_id}`)

    this.data = merge(
      this.data,
      pick(response.data.data, [
        "type",
        "name",
        "folder_id",
        "language_id",
        "code",
        "inspires_publish_at",
        "expires_at"
      ])
    )

    // @TODO: No idea why this doesn't work with pick above, fix later.
    if (response.data.data.code) this.data.code = response.data.data.code

    this.data.partner_market_ids = response.data.data.partner_markets.map(country => country.id)
  },
  methods: {
    onTypeChange() {
      this.data.folder_id = null
    },
    onSuccess(result) {
      this.$notification.success(`Collection **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
